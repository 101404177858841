import {
  convertServerDateStringToLocalDateString,
  WidgetPrivacyReservation,
} from '@gts-ft/utils';

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Container,
  makeStyles,
  Paper,
  useTheme,
  createStyles,
  Box,
} from '@material-ui/core';
import { InfoText, Button, WarningText } from '@gts-common/client';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
  }),
);

interface ReservationProps {
  reservation: WidgetPrivacyReservation | undefined;
  isCancelButtonDisabled: boolean;
  execCancelReservation: () => void;
}

export const Reservation = ({
  reservation,
  isCancelButtonDisabled,
  execCancelReservation,
}: ReservationProps) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  if (reservation) {
    const reservationCancelledAt = reservation.cancelledAt;
    const [cancelDateString, cancelTimeString] = reservationCancelledAt
      ? reservationCancelledAt.split(' ')
      : ['', ''];

    const wasAnonymized = reservation.wasAnonymized;

    let reservationRemark = '';

    if (!wasAnonymized) {
      reservationRemark = reservation.remark ? reservation.remark : 'Keine';
    }

    const showCancelButton = !reservationCancelledAt && !wasAnonymized;

    return (
      <Container maxWidth="sm" disableGutters={true}>
        <Paper className={classes.paper}>
          <Typography align="center" variant="h6" component="h2">
            Reservierung für {reservation.restaurantName}
          </Typography>

          {reservationCancelledAt && (
            <WarningText>
              Diese Reservierung wurde am{' '}
              {convertServerDateStringToLocalDateString(cancelDateString)} um{' '}
              {cancelTimeString} Uhr storniert.
            </WarningText>
          )}

          {wasAnonymized && (
            <InfoText>Diese Reservierung wurde anonymisiert.</InfoText>
          )}

          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Name:</TableCell>
                <TableCell>{reservation.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Anzahl Personen:</TableCell>
                <TableCell>{reservation.personsNo}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Datum/Uhrzeit:</TableCell>
                <TableCell>
                  {reservation.date} {reservation.time}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Telefonnummer:</TableCell>
                <TableCell>{reservation.telephone}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Anmerkung:</TableCell>
                <TableCell>{reservationRemark}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {showCancelButton && (
            <Box mt="1rem" textAlign="center">
              <Button
                label="Stornieren"
                disabled={isCancelButtonDisabled}
                onClick={execCancelReservation}
              />
              {isCancelButtonDisabled && (
                <InfoText>
                  Sie können Ihre Reservierung bis zu 12 Stunden vor
                  Reservierungszeit stornieren. Für kurzfristige Stornierungen
                  melden Sie sich bitte direkt beim Gastronomen.
                </InfoText>
              )}
            </Box>
          )}
        </Paper>
      </Container>
    );
  } else {
    return <Typography>Die Reservierung wurde gelöscht.</Typography>;
  }
};
